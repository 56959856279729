<template>
  <div class="header-box">
    <header>
      <div class="top">
        <img :src="logoImg" alt @click="handleToIndex()" />
        <!-- 封装输入的组件 -->
        <my-search v-show="pos === 'static'"></my-search>
      </div>
    </header>

    <div class="nav-box">
      <nav>
        <template>
          <span class="allTil">
            <i class="el-icon-s-operation"></i> 全部分类
            <div class="nav-menu">
              <ul
                class="menu-wrap"
                v-for="itemA in navMenuList"
                :key="itemA.category_id"
              >
                <li class="menu-item">
                  <span class="firstItem" @click="handleToProA(itemA)">
                    <span>{{ itemA.category_name }}</span>
                    <i class="el-icon-arrow-right"></i>
                  </span>
                  <div class="childrenOneItem">
                    <ul class="chrldrenTwo">
                      <li
                        class="chrldrenTwoItem"
                        v-for="itemB in itemA.children"
                        :key="itemB.category_id"
                        @click.stop="handleToProB(itemA, itemB)"
                      >
                        <span>
                          {{ itemB.category_name }}
                          <i class="el-icon-arrow-right"></i>
                        </span>

                        <ul class="chrldrenThree">
                          <li
                            class="chrldrenThreeItem"
                            v-for="itemC in itemB.children"
                            :key="itemC.category_id"
                            @click.stop="handleToProC(itemA, itemB, itemC)"
                            :class="
                              checkCategory === itemC.category_id ? 'cur' : ''
                            "
                          >
                            {{ itemC.category_name }}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </span>
          <router-link to="/index">9770网页登录</router-link>
          <router-link to="/newProduct">最新上架</router-link>
          <router-link to="/hotProduct">热销推荐</router-link>
          <router-link to="/brandCenter">品牌中心</router-link>
          <router-link to="/helpCenter">帮助中心</router-link>
          <router-link to="/noticeCenter">通知公告</router-link>
          <a href="http://222.143.21.205:8081/" target="_blank">政采商城</a>
        </template>
        <!-- <my-search class="search" v-show="pos === 'fixed'"></my-search> -->
      </nav>
    </div>
  </div>
</template>
<script>
import MySearch from "./MySearch";
import { mapState } from "vuex";
import { catAllList } from "@/api/home";
export default {
  components: {
    MySearch,
  },
  data() {
    return {
      pos: "static",
      t: "",
      navMenuList: [],
      checkCategory: "",
    };
  },
  created() {
    this.getCatAllList();
  },
  methods: {
    handleToProA(a) {
      const sortData = [];
      sortData.push(a);
      this.$router.push({
        path: "/allProduct",
        query: {
          sortData: JSON.stringify(sortData),
        },
      });
    },
    handleToProB(a, b) {
      const sortData = [];
      sortData.push(a, b);
      this.$router.push({
        path: "/allProduct",
        query: {
          sortData: JSON.stringify(sortData),
        },
      });
    },
    handleToProC(a, b, r) {
      const sortData = [];
      sortData.push(a, b, r);
      this.$router.push({
        path: "/allProduct",
        query: {
          sortData: JSON.stringify(sortData),
        },
      });
    },
    getCatAllList() {
      catAllList().then((res) => {
        this.navMenuList = res.data;
      });
    },
    handleToIndex() {
      this.$router.push("/index");
    },
  },
  mounted() {
    window.onscroll = () => {
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        this.pos = "fixed";
      } else {
        this.pos = "static";
      }
    };
  },
  computed: { ...mapState(["logoImg"]) },
};
</script>
<style lang="scss" scoped>
.header-box {
  border-bottom: 2px solid $colorA;
  padding-top: 20px;
  height: 180px;
  margin-bottom: 10px;
  header {
    width: 1200px;
    text-align: left;
    margin: auto;
    img {
      // width: 300px;
      max-width: 500px;
      height: 100px;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      padding: 20px;
      img:hover {
        cursor: pointer;
      }
    }
  }
}
.fix {
  width: 100%;
  height: 40px;
  right: 0;
  top: -10px;
  background-color: white;
  // border-bottom:2px solid $colorA;
  box-shadow: 0 2px 6px $colorA;
  // transition: all 300ms;
}

.nav-box {
  padding: 20px 0;
  z-index: 1000;
}
nav {
  width: 1200px;
  /* border:1px solid red; */
  margin: auto;
  display: flex;
  position: relative;
  bottom: -8px;
}
nav a {
  margin-right: 20px;
  text-decoration: none;
  color: #333;
  font-size: 20px;
  // font-weight: bold;
  margin: 0 30px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  display: inline-block;
  position: relative;
  top: 5px;
}

.allTil {
  display: inline-block;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  background-color: $colorA;
  width: 200px;
  height: 42px;
  line-height: 42px;
  position: relative;
  top: 10px;
}
// .allTil:hover {
//   background-color: $colorA;
//   .nav-menu {
//     display: block;
//   }
// }
.nav-menu {
  position: absolute;
  width: 200px;
  // height: auto;
  min-height: 443px;
  z-index: 9;
  background: $MENUBGC;
  color: $MENUCOLOR;
  box-sizing: border-box;
  .menu-wrap {
    display: flex;
    flex-direction: column;
    font-weight: 100;
    .menu-item {
      height: 30px;
      line-height: 30px;
      padding: 5px 10px;
      margin: 2px 0 2px 2px;
      font-size: 16px;
      .firstItem {
        display: flex;
        justify-content: space-between;
      }
      .firstItem:hover {
        cursor: pointer;
      }
      .el-icon-arrow-right {
        margin-top: 5px;
      }
      &:hover {
        background-color: #ffffff;
        color: $colorA;
        .childrenOneItem {
          display: block;
        }
      }
      .childrenOneItem {
        display: none;
        width: 962px;
        min-height: 443px;
        background-color: $colorG;
        position: absolute;
        top: 0;
        left: 200px;
        border: 1px solid $colorA;
        font-size: 12px;
        box-sizing: border-box;
        // .children {
        //   display: flex;
        //   flex-direction: column;
        .chrldrenTwo {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          // font-weight: bold;
          //   height: auto;
          margin-top: 10px;
          margin-left: 10px;
          text-align: left;
          .chrldrenTwoItem {
            color: $colorA;
            display: flex;
            width: 100%;
            border-bottom: 1px dashed rgb(223, 218, 218);
            span {
              width: 100px;
              text-align: end;
              font-weight: bold;
            }
            span:hover {
              cursor: pointer;
            }
            .chrldrenThree {
              margin-left: 10px;
              .chrldrenThreeItem {
                display: inline-block;
                height: 20px;
                line-height: 20px;
                font-weight: 100;
                padding: 0 3px;
                margin-right: 10px;
                color: #888;
                // border: 1px solid #e5e5e5;
                &.cur {
                  cursor: pointer;
                  color: $colorA;
                  // border: 1px solid $colorA;
                }
              }
              .chrldrenThreeItem:hover {
                cursor: pointer;
                color: $colorA;
                // border: 1px solid $colorA;
              }
            }
          }
        }
        // }
      }
    }
  }
}
.search {
  position: absolute;
  right: 0px;
  top: 10px;
}
nav a:hover {
  color: $colorA;
  /* text-decoration: underline; */
  // border-bottom: 3px solid $colorA;
}
nav a.router-link-exact-active {
  color: $colorA;
  // border-bottom: 3px solid $colorA;
}
</style>
