import request from "@/utils/request";

// 底部配置信息
export function getConfigure(data) {
  return request({
    url: "/api/getConfigure",
    method: "post",
    data: data,
  });
}
// 顶部公告
export function notice(data) {
  return request({
    url: "/api/notice",
    method: "post",
    data: data,
  });
}
// 轮播图
export function bannerList(data) {
  return request({
    url: "/api/rotationMap",
    method: "post",
    data: data,
  });
}
// 9770网页登录展示分类
export function exhibitionHome(data) {
  return request({
    url: "/api/goods/cart/exhibition",
    method: "post",
    data: data,
  });
}
// 分类
export function catAllList(data) {
  return request({
    url: "/api/goods/catAll",
    method: "post",
    data: data,
  });
}
// 获取子集分类
export function catTwo(data) {
  return request({
    url: "/api/goods/catTwo",
    method: "post",
    data: data,
  });
}
// 商品
export function goodsPage(data) {
  return request({
    url: "/api/good/list",
    method: "post",
    data: data,
  });
}
// 热销推荐
export function goodsHotPage(data) {
  return request({
    url: "/api/goods/hot",
    method: "post",
    data: data,
  });
}
// 商品详情
export function goodsDetail(data) {
  return request({
    url: "/api/goods/detail",
    method: "post",
    data: data,
  });
}
// 猜你喜欢
export function guessLink(data) {
  return request({
    url: "/api/goods/guess/like",
    method: "post",
    data: data,
  });
}
// 商品评价
export function commentList(data) {
  return request({
    url: "/api/comment/list",
    method: "post",
    data: data,
  });
}
// 是否收藏
export function isCollect(data) {
  return request({
    url: "/api/goods/isCollect",
    method: "post",
    data: data,
  });
}
// 点击收藏
export function collection(data) {
  return request({
    url: "/api/goods/collection",
    method: "post",
    data: data,
  });
}
// 获取规格
export function goodGetSku(data) {
  return request({
    url: "/api/goods/goodGetSku",
    method: "post",
    data: data,
  });
}
// 获取规格详情
export function goodGetSkuDetail(data) {
  return request({
    url: "/api/goods/goodGetSkuDetail",
    method: "post",
    data: data,
  });
}
// 加入购物车
export function getAddCart(data) {
  return request({
    url: "/api/cart/join",
    method: "post",
    data: data,
  });
}
// 购物车列表
export function getCartList(data) {
  return request({
    url: "/api/cart/list",
    method: "post",
    data: data,
  });
}
// 删除购物车商品
export function delCartProdect(data) {
  return request({
    url: "/api/cart/del",
    method: "post",
    data: data,
  });
}
// 修改购物车数量
export function cartIncrease(data) {
  return request({
    url: "/api/cart/increase",
    method: "post",
    data: data,
  });
}
// 新闻列表
export function pcNotice(data) {
  return request({
    url: "/api/pcNotice",
    method: "post",
    data: data,
  });
}
// 新闻详情
export function noticeDetail(data) {
  return request({
    url: "/api/notice/detail",
    method: "post",
    data: data,
  });
}
